#root{
    width: 100% !important;
    height: 100% !important;
}
.forgot_password_container{
    background-color: #0D5257 !important;
    width: 100% !important;
    height: 100% !important;
    color: white !important;
}

.form-container {
    height: 40vh !important;
    width: 50% !important;
    margin-left: 50px !important
  }

.forgot_password_link{
    color: white !important;
    text-decoration: underline !important;
    margin-top: 30px !important;
    font-size: 0.8em !important;
}

.reset-password-imput{
    width: 45% !important;
}

.forgot-password-txt{
    margin-bottom: 0px !important;
}

.reset_submit_form{
    min-width: 13% !important;
}