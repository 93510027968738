.p-button-custom {
    display: flex !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    background-color: #0D5257 !important;
    color: white;
    border-color: #0D5257 !important;
}

.p-button-add {
    display: flex !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    background-color: #669da0 !important;
    color: white;
    border-color: #0D5257 !important;
    max-width:175px;
}

.p-button-custom-disabled {
    display: flex !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    background-color: #e1e1e1 !important;
    color: #0D5257 !important;
    border-color: #0D5257 !important;
    opacity: 0.6 !important;
}

.dialog-div {
    width: 35vw !important;
    max-width: 600px !important;
}

.p-input-cust {
    border: 1.5px solid !important;
    border-color: #0D5257 !important;
    width: 100% !important;
}

.p-input-cust-dropdown {
    border: 1.5px solid !important;
    border-color: #0D5257 !important;
    width: 50% !important;
}

.p-inputtext.p-invalid.p-component {
    border-color: #E24C4F !important;
}

.p-dropdown.p-invalid.p-component{
    border-color: #E24C4F !important;
}

.p-float-label label {
    left: 10px !important;
    color: #0D5257 !important;
  
}

.password-reset-btn{
    width: 50% !important;  
    margin-left: 5px !important;  
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.p-menubar .p-menubar-root-list > .p-menuitem-active > .p-menuitem-link, 
.p-menubar .p-menubar-root-list > .p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover{
  background-color: #eef1f4 !important;
  border-radius: 0px !important;
  color: #0D5257 !important;
}

.user-type-label{
    font-weight: bold; 
    color: #0D5257 !important; 
}

.user-type{
    color: #0D5257 !important;
}

.form-submit-btn{
    width: fit-content !important
}

.edit-user-btn{
    margin-top :0px !important
}

.action-col{
    width: 3rem !important;
    align-items: center !important;
    text-align: center !important;
}

.manage-user-col {
    min-width: 7rem !important
}

.form-div {
    padding-right: 5px !important;
}
  
.custom-disable-btn{
    opacity: 0.6;
}

.add-user-info{
    color: #0D5257;
    padding-bottom: 15px;
    font-size: 1.1rem;
}

.error-text{
    color: #E24C4F;
    margin-left: 10px;
}

.p-input-icon-left > .p-inputtext {
    max-width: 155px !important;
}

.p-reset-error{
    color: #E24C4F !important; 
}
