.multiple-select{
    text-align: center !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #d9d9d9 !important;
    background-color: #0D5257 !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus{
    box-shadow: 0 0 0 0.2rem #59797b !important;
    border-color: #0D5257 !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover{
    border-color: #0D5257 !important;
}

.status-dropdown{
    margin: 0px 10px 0px 10px !important;
    border :1px solid #0D5257 !important
}

.p-dropdown:not(.p-disabled).p-focus{
    box-shadow: 0 0 0 0.2rem #59797b !important;
}

.search-input{
    border :1px solid #0D5257 !important;

}

.search-input:focus{
    box-shadow: 0 0 0 0.2rem #59797b !important;
}

.p-dropdown:not(.p-disabled):hover{
    border-color: #0D5257 !important;
}

.referral-table{
    margin-top: 2px !important;
    position: relative !important;

}

.row-pb{
    padding-bottom: 25px !important;    
}

.p-menuitem-text {
    color : #0D5257 !important;
}

.p-selection-column{
    text-align: center !important;
}

.p-dropdown .p-dropdown-trigger {
    width: 1.5rem !important;
}

.row-highlight>td{
    box-shadow:inset 0 0 0 9999px rgb(237, 205, 85) !important; /* light yellow */
    color:#0D5257 !important;
    background-color: 237, 205, 85 !important; /* light yellow */
  }

.p-actions-spacing{
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px !important;
}

.rad-list{
    display: flex;
    padding: 0px !important;
    justify-content: space-between !important;
}

.toggle-btn{
    align-self: center !important;
    margin-left: auto !important;
    background: none !important;
    color: #0D5257 !important;
    padding: 0px 3px 0px 0px !important;
    font-size: 1rem !important;
    font-weight: bold !important;
}

.toggle-btn:hover{
    cursor: pointer !important;
}

.rad-list-ul{
    padding-left: 15px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.status-filter{
    margin-left: 5px !important;
}

.p-inputtext:enabled:focus{
    box-shadow: 0 0 0 0.2rem #669da0 !important;
    border-color: #0D5257 !important;
}

.p-input-icon-left > .p-inputtext:hover,
.p-multiselect:not(.p-disabled):hover{
    border-color: #0D5257 !important;
}

.p-multiselect:not(.p-disabled).p-focus{
    box-shadow: none !important;
    border-color: #0D5257 !important;
    font-family: 'Poppins', sans-serif !important;
}

.right-tool-bar{
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}

.right-cont-div{
    margin-left: auto !important;
}

.p-button-actions {
    background-color: #669da0 !important;
    color: white;
    border-color: #0D5257 !important;
    min-height: 40.99px !important;
}

.p-button-actions-disabled {
    background-color: #e1e1e1 !important;
    color: #0D5257 !important;
    border-color: #0D5257 !important;
    opacity: 0.6 !important;
    min-height: 40.99px !important;
}

.exam-status-filter{
    width: 195px !important;
    text-align: left;
    padding-left: 2px !important;
}

.search-filter{
    max-height: 30px !important;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #0D5257 !important;
}

.p-dropdown .p-dropdown-label{
    font-size: 0.8rem  !important;
    color: #0D5257 !important;
    font-family: 'Poppins', sans-serif !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item{
    font-size: 0.8rem  !important;
    color: #0D5257 !important;  
}

.p-paginator .p-dropdown{
    height: 2.5rem !important;
}

.p-paginator .p-dropdown .p-dropdown-label{
    padding: 7px 0px 7px 5px !important
}

.status-dropdown-btn>.p-dropdown .p-dropdown-trigger{
    width: 40px !important;
}

.followup-input>.p-calendar> .p-inputtext::placeholder{
    color: #0D5257 !important;
    font-size: 0.9rem !important;
}

.followup-input> .p-inputtext{
    color: #0D5257 !important;
}

.p-datatable .p-paginator-bottom{
    width: 100% !important;
    display: flex !important;
    justify-content: end !important;
    padding: 0 !important;
}

.p-paginator> .p-paginator-first{
    margin-left: auto !important;
}

.followup-date> .p-inputtext {
    pointer-events: none !important;
    border-color: #eee !important;
    background-color: #eee !important;
    height: 0px !important;
    width: 0px !important;
    padding: 0px !important;
    border: none !important;
}

.followup-date> .p-button{
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    width: 30px !important;
    height: 30px !important;
}

.rad-list> .p-calendar> .p-datepicker-trigger{
    background-color: #669da0 !important;
    border-color: #669da0 !important;
}

.p-datepicker-prev, .p-datepicker-next{
    color: #0D5257;
    display: inline !important;
    padding: 5px;
}

.p-datepicker{
    width: 357px !important;
}

.exam-update-select{
    display: inline-block !important;
    margin: 2px !important;
    padding : 2px !important;
    
}

.p-multiselect .p-multiselect-label{
    padding: 0.3rem 0.75rem !important;
    font-size: 0.9rem !important;
    color: #0D5257;
}

.row-pb> .p-float-label> .p-multiselect{
    border: 1px solid #0D5257 !important;
}

.status-dropdown-btn> .p-dropdown{
    border: 1px solid #0D5257 !important;
}

.p-menu.p-menu-overlay{
    width: fit-content !important;
}

.followup-date{
    text-align: center !important;
}

.followup-div{
    width: 100% !important;
    text-align: center !important;
}

.p-calendar> .p-inputtext{
    font-size: 0.9rem !important;
}

.multiselect-div{
    min-height: 30px !important;
}

.p-multiselect-item{
    color: #0D5257 !important;
}

.multiselect-filter{
    min-width: 195px !important;
    text-align: left !important;
}

.followup-input> .p-calendar> .p-inputtext{
    box-shadow: none !important;
    border-color: #0D5257 !important;
    font-family: 'Poppins', sans-serif !important;
}
.exam-details{
    margin: 0
}
.exam-details-title {
    font-weight: 700;
}
.exam-details-button {
    background-color: #669da0 !important; 
    color: white; 
    padding: 9px 23px;
    border-color: #0D5257 !important;
    margin: 0px 8px !important;
    padding: 9px 23px !important;
    height: 40px !important;

}
.exam-details-button-disabled {
    padding: 9px 23px !important;
    margin: 0px 8px !important;
    background-color: #e1e1e1 !important;
    color: #0D5257 !important;
    border-color: #0D5257 !important;
    opacity: 0.6 !important;
    height: 40px !important;
}

.exam-details-button> .p-button-icon{
    margin-right: 5px;
}
.exam-details-button-disabled> .p-button-icon{
    margin-right: 5px;
}
.exam-details-info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.exam-details-follow-up-date>input {
    color: #0D5257 !important;
    font-family: 'Poppins', sans-serif !important;
    font-size: 0.9rem !important;
}

.exam-details-status{
    min-width: 227px !important;
}

.exam-status-filter> .p-dropdown-label, .exam-col> .p-dropdown> .p-dropdown-label{
    padding: 0.3rem 0.75rem !important
}

.update-exam > .p-button {
    min-height: 30px !important;
    margin-left: 3px !important;
    padding: 0px 20px 0px 20px!important;
}

.search-filter-div{
    display: flex !important;
}

.p-tooltip-text {
    background-color: #0D5257 !important;
    color: white !important;
    font-family: 'Poppins', sans-serif !important;
    margin-bottom: 20px !important;
}

.exam-cell-dropdown {
    width: 170px !important;
    text-align: left !important;
    margin-left: -1.5px !important;
}

.referral-table> .p-paginator-bottom> .p-paginator-current{
    padding: 0 0 !important;
    color: #0D5257 !important;
}

.filter-menu{
    align-items: center !important;
    text-align: center !important;
}

.filter-menue-items{
    margin-bottom: 10px !important;
}
 
.filters-panel> .p-overlaypanel-content{
    border: 2px solid !important;
    border-radius: 10px !important;
    border-color: #0D5257;
}

.filters-panel> ::before{
    display: none !important;
}

.p-overlaypanel:before::after{
    display: none !important;
}

.filters-panel{
    border-radius: 10px !important;
}

.filter-menu-btn{
    margin-right: 10px !important;
    width: 120px !important;
    display: flex !important;
    align-items: center !important;
    min-height: 30px !important;
    margin-left: 3px !important;
    padding: 0px 20px 0px 0px !important;
}

.p-overlaypanel .p-overlaypanel-close:enabled:hover, .p-overlaypanel .p-overlaypanel-close{
    background: #669da0 !important;
}

.filters-available{
    border-color: black !important;
    color: white !important;
    background-color: #0D5257 !important;
}

.filters-not-available{
    border-color: #0D5257 !important;
    color: #0D5257 !important;
    background-color: white !important;
}

.p-overlaypanel:before, .p-overlaypanel:after{
    left: calc(var(--overlayArrowLeft, 0) + 7rem) !important;
}

.filter-menu> .p-label> .multiselect-div> .p-multiselect-label-container> .p-multiselect-label{
    width: 150px !important;
    text-align: left !important;
}

.apply-filter-button{
    width: 92px !important;
    font-size: 0.8rem !important;
    padding: 0px 0px !important;
}

.clear-filters-btn{
   background-color: #e4e3e3 !important; 
   color: #0D5257 !important;
   border-color: #0D5257 !important;

}

.custom-filter-icon{
    background-size: contain !important;
    background-repeat: no-repeat !important;
    width: 1.2rem !important;
    height: 1.5rem !important;
    display: inline-block !important;
    margin-right: 0.3rem !important;
}

.multiselect-filter> .p-multiselect-trigger{
    justify-content: right !important;
    padding-right: 2px !important;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
    color: #0D5257 !important;
}
.upload-exam-content {
    background: #f8f8f8;
    margin: 0px !important;
    border: 1px solid #000;
}
.upload-exam-details{
    margin: 0;
    font-size: 12px;
    margin-bottom: 5px;
}
.upload-exam-details-title {
    font-weight: 700;
    font-size: 12px;
}

.m-0{
    margin:0em;
}
.d-flex{
    display: flex;
}
.mt-10{
    margin-top: 10px;
}
.justify-end{
    justify-content: end;
}
.justify-center{
    justify-content: center;
}
.font-size-12{
    font-size: 12px !important;
}
.upload-exam-details-file-title {
    font-weight: 700;
    font-size: 16px;
}

.dialog-div-upload {
    width: 48vw !important;
    max-width: 870px !important;
    max-height: 650px !important;
}
.mb-12{
    margin-bottom: 12px;
}
.ml-12{
    margin-left: 12px !important;
}
.p-fileupload .p-fileupload-buttonbar{
    padding-top: 7px !important;
    padding-bottom: 7px !important;
}
.p-fileupload .p-button{
    background: #627677 !important;
    border-color: #627677;
    padding: 7px 7px !important;
}
.p-fluid .p-fileupload .p-button{
    width: inherit !important;
}
.p-fileupload .p-fileupload-content {
    padding: 1rem !important
}
.file-pick{
    width: inherit !important; 
    margin-right: 10px; 
    margin-top: 10px;
    margin-bottom: 10px; 
}
.p-fileupload-row {
    display: flex;
    align-items: center;
}
.flex-wrap {
    flex-wrap: wrap !important;
}
.align-items-center {
    align-items: center !important;
}
.flex-file {
    display: flex !important;
}
.space-between{
    justify-content: space-between;
}
.ml-3{
    margin-left: 3px;
}
.validation-msg {
    color: red;
    margin-top: 10px !important;
    font-size: 12px !important;
}
.upload-btn {
    background: #0D5257 !important;
    border-color: #0D5257;
    padding: 7px 7px !important;
}
.toggle-btn-disabled {
    align-self: center !important;
    margin-left: auto !important;
    background: none !important;
    color: #0d525794 !important;
    padding: 0px 3px 0px 0px !important;
    font-size: 1rem !important;
    font-weight: bold !important;
}
.p-button .p-button-label{
    font-size: 12px !important;
}
.upload-button {
    background-color: #0D5257 !important; 
    color: white; 
    padding: 9px 23px;
    border-color: #0D5257 !important;
    margin-top: 10px !important;
    font-family: 'Poppins' !important;  
    justify-content: center;
    font-weight: 700 !important;
    display: unset !important;
}
.pi-cloud-upload::before{
    padding-right: 5px;
}

.p-2-rem {
    padding: 1rem !important;
}

.p-button.p-button-sm .p-button-icon {
    font-size: 10px !important;
}
.icon-btn-upload {
    background: none !important;
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
    cursor: pointer !important;
    color: #0D5257 !important;
    box-shadow: none !important;
    min-width: auto !important;
}
.icon-btn-upload-alert {
    background: none !important;
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
    cursor: pointer !important;
    color: #bebe4e !important;
    box-shadow: none !important;
    min-width: auto !important;
}
.p-button.p-component.icon-btn-upload.p-button-icon-only {
    background: none !important;
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
    cursor: pointer !important;
    color: #0D5257 !important;
    box-shadow: none !important;
    min-width: auto !important;
}

.p-button.p-component.icon-btn-upload.p-button-icon-only .pi{
    font-size: 15px !important;
    color: #0D5257 !important;
    margin: 5px !important;
    font-weight: 700 !important;
}
.custom-icon-button .pi{
    font-size: 18px !important;
    color: #0D5257 !important;
    margin: 5px !important;    
}

.icon-btn-upload .p-button:active,
.icon-btn-upload .p-button:focus,
.icon-btn-upload .p-button:hover{
    background: none !important;
    box-shadow: none !important;
}

.icon-btn-upload.p-button:focus{
    outline : none !important;
}

.pi-exclamation-triangle> .p-button.p-component.icon-btn-upload.p-button-icon-only .pi{
    color: yellow !important;
}

.notes-icon{
    border: 2px solid !important;
    border-color: #000 !important;
    background-color: rgb(219, 219, 177) !important;
    color: #818080 !important;
    padding-top: 1px !important;
    width: 25px !important;
    height: 25px !important;
    font-size: 17px !important;
    display: inline-block !important;
}

.astrisk{
    color: red !important;
    font-style: normal !important;

}

.notes-icon-disabled{
    opacity: 0.4 !important;
    border: 2px solid !important;
    border-color: #000 !important;
    color: #a3a3a3 !important;
    padding-top: 1px !important;
    width: 25px !important;
    height: 25px !important;
    font-size: 17px !important;
    display: inline-block !important;
}

.accession-number{
    margin-top: 10px !important;
    width: 400px !important;
    color: var(--text-color-secondary) !important;
    display: inline-flex !important;
    align-items: center !important;

}

.accession-number>label{
    width: 260px !important;
}

.required-icon{
    color: red !important;
}


.notes-tooltip{
    max-width: 200px !important;
    font-size: 11px !important;
}

.p-tooltip-text{
    background-color: #efefd4 !important;
    color: #000 !important;
    border: 1px solid !important;
    border-color: #000 !important;
    box-shadow: none !important;
}

.file-upload-div{
    display: flex !important;
}

.file-upload-div>i{
    margin-right: 5px !important;
}

.file-upload-div>.p-fileupload{
    width: 100% !important;
}

.ml-10{
    margin-left: 10px !important;
}

.p-fileupload .p-fileupload-row > div {
    padding: 0rem 1rem !important;
}

div.p-fileupload-content > div.flex-file.align-items-center.flex-wrap.space-between > div.flex-file.align-items-center > span,
div.p-fileupload-content > div:nth-child(3) > div > div > div.flex-file.align-items-center > span{
    text-align: left !important;
    white-space: nowrap  !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: block !important;
}

.prog-spinner-cont {
    position: relative; 
    display: inline-block;
}
.progress-value-font {
    position: absolute;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%); 
    font-size: 0.8rem !important;
    font-weight: 600
}