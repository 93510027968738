.contact-email{
    color: #0D5257 !important;
    font-weight: 1000 !important;
    text-decoration: underline;
  }
  
  .contact-description{
    font-size: 1.2rem !important;
  }

  .help-txt{
    color:  white !important;
    margin: 2px 0px 0px 5px !important;
    padding: 0px !important;
    width: fit-content !important;
    background : transparent !important;
  }