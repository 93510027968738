:root {
  --text-color: #fff;
  --backgroung-color: #0D5257;
  --label-color: #0D5257;
  --border-color: #0D5257;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.p-menubar {
  padding: 0.2rem !important;
  background: var(--backgroung-color) !important;
  color: #eef1f4 !important;
  border: none !important;
  border-radius: 0px !important;
  height: 55.6px !important;
}




.p-menubar ul{
  margin: 0 !important;
  margin-bottom: -22px !important;
  padding: 0px !important;
  padding-left: 5em !important;
}

.p-menubar .p-menubar-root-list > .p-menuitem,
.p-menuitem:hover {
  border-radius: 0px !important;
  background-color: rgb(255, 255, 255, 0.3) !important;
  color: white !important;
  margin-right: 10px;
}

.p-dialog .p-dialog-header .p-dialog-header-icon, .p-dialog-header-icon:enabled:hover,
.p-dialog .p-dialog-header .p-dialog-header-icon:hover  {
 color: #0D5257 !important;
 background-color: white !important;
} 

.fw-400 {
  font-weight: 400;
}

.p-dialog .p-dialog-header {
  background: #0D5257 !important;
  color: #fff !important;  
  padding: 0.5rem !important;
}
.p-dialog .p-dialog-content {
  padding: 1% 2.4% 0.7% 3% !important;
}
.p-dialog .p-dialog-footer{
  display: flex !important;
}
.p-mb-1{
  margin-bottom: 2rem !important;
}

.p-datatable .p-datatable-thead > tr > th{
 background-color: #0D5257 !important;
 color: white !important  ;
 padding: 0.5rem !important;
 font-weight: 100;
 border: none !important;
}

.p-datatable .p-sortable-column .p-sortable-column-icon{
  color: white !important;
  font-weight: 700 !important;
  font-size: 0.8em !important;
}

.p-datatable-table{
 border-spacing: 2px !important;
}  

.pl-0{
  padding-left: 0px;
}

.pl-5{
  padding-left: 5px !important;
}

.pr-5{
  padding-right: 5px !important;
}

.mt-10{
  margin-top: 10px;
}

.p-datatable .p-datatable-thead > tr > th{
  text-align: center !important;
  border-radius: 5px;
  font-weight: 700 !important;
  font-size: 0.8em !important;
}

.p-icon{
 height: 0.8rem !important;
}
.p-datatable .p-datatable-tbody > tr > td{
 background-color: #eee;
 border-radius: 5px !important;
 padding: 0rem 0.5rem !important;
 height: 50px !important;
 font-size: 0.8em !important;
}

.row-height-custom{
  height: 30px !important;
}

.p-menuitem-active > a > span.p-menuitem-text {
   color: #0D5257 !important;
}

.p-menuitem:hover > a > span.p-menuitem-text {
  color: #0D5257 !important;
  border: none !important;
}

.p-toolbar{
  background-color: white !important;
  border: none !important;
  padding: 0px !important;
}

.p-menubar:hover .p-menubar-root-list > .p-menuitem > .p-menuitem-link{
  border-radius: 0px !important;
}
.p-button-outlined{
   color: #0D5257 !important;
}

.form-input-cust{
  border: 1.5px solid !important;
  border-color: #0D5257 !important;
}

.p-paginator .p-paginator-pages .p-paginator-page{
  background-color: rgb(0, 92, 98, 0.6) !important;
  color: white !important;
  border-radius: 25% !important;
  min-width: 2rem !important;
  height: 2rem !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight{
  background-color: #0D5257 !important;
  color: white !important;
  border-radius: 25% !important;
  min-width: 2rem !important;
  height: 2rem !important;
}

.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover{
  background-color: #0D5257 !important;
  color: white !important;
  border-radius: 25% !important;
  min-width: 2rem !important;
  height: 2rem !important;


}

.p-paginator .p-paginator-first, 
.p-paginator .p-paginator-prev, 
.p-paginator .p-paginator-next, 
.p-paginator .p-paginator-last{
  background-color: rgb(0, 92, 98, 0.6) !important;
  color: white !important;
  border-radius: 25% !important;
  min-width: 2rem !important;
  height: 2rem !important; 
}

.p-datatable-tbody tr td {
    color: #0D5257 !important;
}

.p-button.p-button-outlined{
  border: none !important;
}

.p-button.p-button-outlined:hover{
  border: 1px solid !important;
}

.p-paginator{
  float: right;
} 

.p-fluid .p-inputtext{
  color: #0D5257 !important;
}

.p-component-overlay{
  padding: 5% !important;
}

.p-paginator .p-paginator-last:hover, 
.p-paginator .p-paginator-next:hover, 
.p-paginator .p-paginator-prev:hover,
.p-paginator .p-paginator-first:hover{
  background-color: #0D5257 !important;
}

.p-toast .p-toast-message.p-toast-message-success{
  border: solid rgb(0, 92, 98, 0.6) !important;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon.p-icon,
.p-toast .p-toast-message .p-toast-message-content .p-toast-summary,
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close{
  color: white !important;
}

.toast-div{
  width: 100% !important;
  text-align: center !important;
}

.toast-div-content{
  text-align: center !important;
  display: inline-flex !important;
  width:fit-content !important;
  align-items: center !important;
}

.spinner-custom{
  color: white !important;
  width: fit-content;
  width : 30px !important;
  height: 30px !important;
}

.toast-txt{
  color: white;
  width: 100%; 
  margin-left: 0px;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight{
  color: #0D5257 !important; 
}

.p-toast{
  opacity: 1 !important;
}

.p-mb-5{
  margin-bottom: 10px;
}

.error-toast{
  background-color: #d85d5f !important;
  align-items: center !important;
}

.success-toast{
  background-color: rgb(56, 134, 140) !important;
}

.p-toast .p-toast-message.p-toast-message-success {
  border: solid rgb(56, 134, 140) !important;
  align-items: center !important;
  text-align: center !important;
}
.p-toast .p-toast-message.p-toast-message-error {
  border: none !important;
  background-color: #d85d5f !important;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon.p-icon{
  display: none !important;
}

.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon, 
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close{
  color: white !important;
}

.p-toast-message-content{
  align-items: center !important; 
  text-align: center !important;
}

.p-datatable .p-column-header-content{
  display: inline !important;
}

.p-menubar .p-menuitem-link:focus{
  box-shadow: none !important;
}

.p-datepicker-trigger{
 background-color: #0D5257 !important;
}

.form-submit{
  display: inline-flex !important;
  align-items: center !important;
  width: 100% !important;
}

.h1-b{
  font-weight: bold !important;
}

.pt-5{
  padding-top: 5px !important;
}

.mw-150{
  min-width: 150px !important;
}

.mw-75{
  min-width: 80px !important;
}

.mw-70{
  min-width: 70px !important;
}

.followup-col{
  text-align: center !important;
}

.p-datepicker-title{
  display: flex !important;
}

.mfa-view-parent{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.display-none{
  display: none !important;
}

.user-submit-btn {
  width: 40% !important;
}

.user-mfa-form{
  width: 100% !important;
}

.desktop-input> .p-inputtext:enabled:focus{
  box-shadow: none !important;
}

.desktop-input> .p-password-input {
  padding: 0px !important;
}

.field> .p-input-icon-right> .p-inputtext{
  color: white !important;
}

.mfa-input-field{
  display: block !important;
  text-align: left !important;
  margin-top: 10px !important;
}

.w-80{
  width: 70% !important
}

.w-40{
  width: 40px !important;
}

.mw-40{
  min-width: 40px !important;
}

.w-10-per{
  width: 10% !important;
}

.w-6-per{
  width: 6% !important;
}

.w-2-per{
  width: 2% !important;
}

.exam-col{
  width: 180px !important;
  max-width: 180px !important;
  text-align: center !important;
}

.apply-filter > .p-button {
  min-height: 30px !important;
  padding: 0px 5px 0px 5px!important;
  margin-right: 5px !important;
}

@media(min-width: 960px){
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled) .p-menuitem-text{
    color: white !important;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text{
    color: #0D5257 !important;
  
  }
}

@media screen and (max-width: 450px){
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled) .p-menuitem-text{
    color: #0D5257 !important;
  }
}

@media screen and (max-width: 880px) and (min-width: 450px){
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled) .p-menuitem-text{
    color: #0D5257 !important;
  }
}

