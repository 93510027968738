.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.banner {
  padding: 10px 0px 10px 0px;
  background-color: #0D5257 !important;
}
.custom-auth-container{
  width: 350px;
  margin: auto;
}
.password-field input{
  width: 100% !important;
}
.custom-auth-container button{
  cursor: pointer !important;
  height: 36px;
  
}
.custom-auth-container button label{
  cursor: pointer !important;
  height: 36px;
}
.padding-10{
  padding: 10px;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logout-button {
  border: 1px;
  background-color: #eee;
  cursor: pointer;
  margin-right: 20px;
  width: 6em;
  height: 25px;
  padding: 5px;
  color: #0D5257;
  transform: translatey(30%);
}

.buttons-holder {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 10px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: #282c34;
  background-color: #282c34b9;; /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color:white;
  margin: auto;
  padding: 20px;
  border: 1px solid  #282c34;
  width: 450px;
  color: #0D5257;
}
.btn-container {
  background-color:white;
}
.btn-container > button {
  padding: 5px;
  margin-right: 5px;
  background-color: #0d5257;
}

button {
  background: #6495ED;
  color: white;
  padding: 16px 25px;
  margin: 15px auto;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  display: block;
}

.desktop-text{
  visibility: hidden;
}

.email-input{
  border-radius: 5px !important;
  background: transparent !important;
  width: 100% !important;
  height: 35px !important;
  margin-top: 10px !important;
  box-shadow: none !important;
  padding: 1px 5px 1px 5px !important;
}

.email-input:focus{
  outline: 1px solid white !important;
}

.landing-heading{
  width: 100%;
  font-size: 32px;
}

.signon-btn{
  margin-top: 20px !important;
  width: fit-content !important;
  border-radius: 5px !important;
  border: 1px solid white !important;
  height: 30px !important;
  background: rgba(255, 255, 255, 0.2) !important;
  color: white !important;
  padding: 1px 5px 1px 5px !important;
  font-size: 15px !important;
  cursor: pointer !important;
}

.pasword-reset-form-field{
  margin-top: 10px;
}
.pasword-reset-form-field input {
  margin-top: 2px;
}
.password-reset-success p{
  font-size: medium;
}
.password-reset-success a{
  color: white;
  text-decoration: underline;
}
.password-reset-error p{
  font-size: medium;
  color: white;
}
.password-reset-error a{
  color: rgb(255, 255, 255);
  text-decoration: underline;
}
.password-reset-error a:hover{
  color: rgb(255, 255, 255);
}
.password-field div {
  width: 100% !important;
}
.password-reset-success a:hover {
  color: rgba(255, 255, 255, 0.5);
}
.signon-btn:hover{
  background: rgba(255, 255, 255, 0.5) !important;
}

.forgot-pwd-link{
  color: white;

}

.top{
  margin-top: 0px;
}


.col.cl2{
  width : 100%;
}

.pasword-reset-form-field> .p-error, .error-msg{
  color: rgb(231, 94, 94);
  text-align: justify !important;
  word-wrap:break-word;
  font-weight: bold;
  padding-top: 10px;
}

.custom-auth-container> .error-msg{
  text-align: center !important;
}

.success-msg{
  color: rgb(110, 174, 110);
  padding-top: 10px;
  width: 100% !important;
  text-align: center !important;
}

.mb-10{
  margin-bottom: 10px;
}

.mfa-submit-btn{
  min-width: 25% !important;
  background: rgba(255, 255, 255, 0.2) !important;
  color: white !important;
  margin-left: 0px !important;
  margin-top: 20px !important;
  border-radius: 5px !important;
  border: 1px solid white !important;
  height: 30px !important;
  padding: 1px 5px 1px 5px !important;
  font-size: 15px !important;
  cursor: pointer !important;
}

.pin-input-parent{
  border-radius: 10px;
  padding: 5px 10px 5px 20px;
  text-align: center;
  width: fit-content;
}

.pin-input-parent-error{
  border: 2px solid rgb(156, 2, 2);
  border-radius: 10px;
  padding: 5px 10px 5px 20px;
  text-align: center;
  width: fit-content;
}

.desktop-input> .p-inputtext{
  background-color: transparent !important;
  border: none
}

.mt-0{
  margin-top: 0px !important;
}
.mt-20{
  margin-top: 20px;
}
.mr-50{
  margin-right: 50px;
}
.mb-0{
  margin-bottom: 0px;
}
.mb-6{
  margin-bottom: 6px;
}
.ml-50{
  margin-left: 50px;
}

.pt-20{
  padding-top: 20px;
}
.fw-200{
  font-weight: 200;
}

.ta-left{
  text-align: left;
}
.w-205{
  width: 205px;
}

@media(min-width: 960px){

  .header-image {
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
    min-height: 88vh;
  }
  
  .grid-container.flush.home__top .mobile-text{
    display: none;
  }

  .col.c6--md{
    padding-top: 5%;
    width: 30%;
    margin-left: 60%;
  }
  
  .home-top .desktop-text{
    display: inline-block!important;
  }

  .desktop-text{
    visibility: visible;
  }

  .email-input{
    border: 1px solid white !important;
    color: white !important;

  }

  .email-input::placeholder{
    color: white !important ;
    opacity: 0.8 !important;
  }

  .signon-btn{
    border: 1px solid white !important;
    background: rgba(255, 255, 255, 0.2) !important;
    color: white !important;
    width: fit-content !important;
    margin-left: 0px !important;

  }
  .lgn-pg-btn{
    min-width: 25% !important;
    font-size: 16px !important;
  }

  .menu-logo-container{
    padding-top: 40px;
    padding-left: 40px;
  }

  .pin-input{
    width: 25px;
    height: 35px;
    margin-right: 10px;
    background: transparent;
    color: white;
    border-left: transparent;
    border-right: transparent;
    border-top: transparent;
    border-bottom: 2px solid white;
    box-shadow: none;
    font-size: 1.3rem;
    text-align: center;
  }
  
  .pin-input:focus{
    outline: none;
  }
  
  .help-container{
    display: flex;
  }
  
  .help-info{
    color: white;
    text-decoration: underline;
    margin: 5px;
    cursor: pointer;
    margin-top: 5px !important
  }
  
  .help-info:hover{
    color: rgb(62, 131, 160);
    text-decoration: underline;
    margin: 5px;
    cursor: pointer;
    font-weight: 500 !important;
  }


  .pin-input-parent{
    border: 2px solid white;
  }

  .desktop-input> .p-password-input::placeholder {
    color: white !important;
  }

  .desktop-input> .p-inputtext{
    color: white !important;
  }
  
}


@media screen and (max-width: 450px){
  .home-top{
    background-position-x: -70px;
    background-position-y:0px;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: none;
    min-height: 65vh;
    padding-left: -120px;

  }

  .desktop-text{
    visibility: hidden;
  }

  .email-input{
    border: 1px solid rgb(0, 92, 98) !important;
    color: rgb(0, 92, 98) !important;
    width: 100% !important;
  }

  ::placeholder{
    color: rgb(0, 92, 98);
  } 

  .forgot-pwd-link{
    color: rgb(0, 92, 98);
  
  }
  
  .mobile-header{
    padding: 30px 20px 30px 20px;
    text-align: center;
  }
  
  .landing-subtext{
    align-items: center;
  }


  .lgn-pg-btn{
    min-width: 25% !important;
    border: 1px solid rgb(0, 92, 98) !important;
    color: white !important;
    background: rgb(0, 92, 98) !important;
    
  }

  .lgn-pg-btn:hover{
    background: rgba(0, 92, 98, 0.2) !important;

  }
  .menu-logo-container{
    padding-top: 20px;
    padding-left: 20px;
  } 

  .pin-input{
    width: 25px;
    height: 35px;
    margin-right: 10px;
    background: transparent;
    color: #0D5257;
    border-left: transparent;
    border-right: transparent;
    border-top: transparent;
    border-bottom: 2px solid #0D5257;
    box-shadow: none;
    font-size: 1.3rem;
    text-align: center;
  }
  
  .pin-input:focus{
    outline: none;
  }
  
  .help-container{
    display: flex;
  }
  
  .help-info{
    color: #0D5257;
    text-decoration: underline;
    margin: 5px;
  }
  
  .help-info:hover{
    color: #0D5257;
    text-decoration: underline;
    margin: 5px;
    font-size: 1rem;
  }

  .pin-input-parent{
    border: 2px solid #0D5257;
  }

  .desktop-input> .p-password-input::placeholder {
    color: #0D5257 !important;
  }
  
  .desktop-input> .p-inputtext{
    color:#0D5257 !important;
  }
}

@media screen and (max-width: 990px) and (min-width: 450px){
 
  .header-image {
    background-size: cover;
    background-repeat: no-repeat;
    height: 70vh;
    min-height: 88vh;
    background-position-x: -120px;
  }

  .mobile-header{
    padding: 30px 20px 30px 20px;
    text-align: center;
  }
  

  .email-input{
    border: 1px solid rgb(0, 92, 98) !important;
    color: rgb(0, 92, 98) !important;
    width: 55%;
    align-items: center !important;

  }
 
  ::placeholder{
    color: rgb(0, 92, 98);
  } 

  .forgot-pwd-link{
      color: rgb(0, 92, 98);
  }
 

  .lgn-pg-btn{
    min-width: 25% !important;
    border: 1px solid rgb(0, 92, 98) !important;
    color: white !important;
    background: rgb(0, 92, 98) !important;

  }

  .lgn-pg-btn:hover{
    background: rgba(0, 92, 98, 0.2) !important;

  }

  .menu-logo-container{
    padding-top: 20px;
    padding-left: 20px;
  } 

  .pin-input{
    width: 25px;
    height: 35px;
    margin-right: 10px;
    background: transparent;
    color: #0D5257;
    border-left: transparent;
    border-right: transparent;
    border-top: transparent;
    border-bottom: 2px solid #0D5257;
    box-shadow: none;
    font-size: 1.3rem;
    text-align: center;
  }
  
  .pin-input:focus{
    outline: none;
  }
  
  .help-container{
    display: flex;
  }
  
  .help-info{
    color: #0D5257;
    text-decoration: underline;
    margin: 5px;
  }
  
  .help-info:hover{
    color: #0D5257;
    text-decoration: underline;
    margin: 5px;
  }

  .pin-input-parent{
    border: 2px solid #0D5257;
  }
  
  .desktop-input> .p-password-input::placeholder {
    color: #0D5257 !important;
  }

  .desktop-input> .p-inputtext{
    color:#0D5257 !important;
  }
}

@media print {
  .exam-details-button,
  .exam-details-button-disabled {
    display: none !important;
  }
}

.div-left {
  width: 20%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.div-right {
  width: 80%;
  margin-bottom: 10px;
}
.display-flex{
  display: flex;
}
.p-inputtextarea-resizable{
  overflow-y: auto !important;
  min-width: 385px;
  max-height: 85px;
  min-height: 62px;
}
.txt-ar-notes-char-length{
  min-width: 385px;
  text-align: right;
  width: 340px !important;
  font-size: 11px !important;
}