.tc-dialog-div> .p-dialog-content {
  padding: 2% 2.4% 0.7% 3% !important;
}

.tc-dialog-div {
  width: 70% !important;
  max-width: 70% !important;
  height: 70% !important;
}

.tc-dialog-div>.p-dialog-header>.p-dialog-header-icons>.p-dialog-header-icon {
  display: none !important;
}

.tc-btn-container {
  width: 130px;
  margin-top: 10px;
}

.terms-condition-txt {
  overflow-y: auto;
  color: #0D5257;
  border: 1px solid #0D5257;
  border-radius: 5px;
  padding: 5px;
}

.user-aggrement {
  height: 100%;
}

.tc-btn-div {
  display: flex !important;
}

.agree-checkbox-parent {
  margin-top: 10px;
  margin-bottom: 10px;
}

.continue-btn {
  width: 100px;
}

.agreement-title {
  color: #0D5257 !important;
}

.continue-btn-div {
  display: flex !important;
  justify-content: center !important;
}

.agree-label {
  color: #0D5257 !important;
  font-weight: bold;
  margin-left: 5px !important;
}

.agree-checkbox {
  accent-color: #0D5257;
  min-width: 18px !important;
  min-height: 18px !important;
}

.agree-checkbox-container {
  display: flex !important;
}

.hyper-link {
  text-decoration: underline;
}

.limit-width {
  text-align: justify !important;
}